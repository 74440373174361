import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
const BrandLogo = styled.div`
 position:relative;
 margin-left:auto;
 margin-right:auto;
 max-width:200px;
 margin-top:10px;
`
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO
        title="APC Power Management Solutions for Businesses"
        description="APC or American Power Conversion is widely regarded as an industry leader in physical infrastructure and software solutions for power management for both commercial and residential markets."/>
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">apc</Link>
          </BreadCrumb>
          <PageTitle>APC: Power Management Solutions</PageTitle>
          <BrandLogo>
            <StaticImage
              src="../images/brand-1.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Letast Article"
              placeholder="blurred"
            />
          </BrandLogo>
          <p><strong>APC</strong> believes that both data and connectivity are crucial for business success in today’s world. APC solutions ensure that they’re ready and available whenever – and wherever – you and your customers need them. Through their technological products & services, this is how APC helps to provide Certainty in a Connected World.</p>
        </div>
      </SectionBgAfter>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Why APC?</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>The American Power Conversion Corporation was started in a New England garage in 1981. Thanks to years of continued innovation, APC is now a flagship brand of Schneider Electric, and is widely regarded as an industry leader in physical infrastructure and software solutions for both commercial and residential markets.</p>
                <p>In today’s connected world, you deserve integrated solutions for the transformation of IT, and APC is here to deliver those solutions. Here are a few distinctives about APC:</p>
                <ul>
                  <li><strong>Enabling IT trends and technology</strong>
                    <p>APC prides itself on uncovering IT insights which continue to help industries evolve and grow.</p>
                  </li>
                  <li>
                    <strong>Market leadership and innovation</strong>
                    <p>In many ways, the story of APC is really a story of market leadership and innovation. Our past has truly positioned us to be able to continue helping you in the future.</p>
                    </li>
                  <li><strong>Integrated solutions and capabilities</strong>
                    <p>If you want to take full advantage of your business opportunities, you need integrated solutions and capabilities. APC is here to help provide those dynamic IT solutions.</p>
                  </li>
                  <li>
                    <strong>Alliances and partnerships</strong>
                    <p>The APC story of growth also includes the integration of many vendors and the building of a robust community of business partnerships. Our alliances and partnerships enable complete solutions for your business.</p>
                  </li>
                </ul>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

      {/* <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">
          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
         <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
        </div> 
      </Section>*/}
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">A Few Examples of APC Products We Install & Configure at WYN Technologies</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <h3>UNINTERRUPTIBLE POWER SUPPLY (UPS)</h3>
                <p>APC offers a wide range of power protection solutions for everything from data centers, to industrial environments, to home entertainment systems.</p>
                <h3>Computers & Peripherals</h3>
                <ul>
                  <li>Back-UPS battery backup offers guaranteed power and surge protection for computer, wireless networks, and other electronics.</li>
                  <li>Back-UPS PRO offers guaranteed power protection for high-performance computer systems, routers/modems, and other electronic devices.</li>
                  <li>Back-UPS Connect is an innovative UPS product designed to power networking equipment for prolonged periods of time in the event of a blackout.</li>
                 </ul>
                <h3>Network & Server</h3>
                <ul>
                  <li>Smart-UPS are trusted by IT professionals around the world to supply reliable, network-grade power without interruptions.</li>
                  <li>Smart-UPS Online provides power protection for servers, voice/data networks, medical labs, and light industrial applications.</li>
                  <li>Smart-UPS Battery Systems are external, extended-run battery systems which support Smart-UPS applications during prolonged power outages.</li>
                  <li>Symmetra’s modular, redundant architecture delivers the highest level of business continuity possible. Symmetra can scale power and run time as demand increases, or whenever higher levels of availability are needed.</li>
                </ul>
                <ul>
                    <li>
                    <strong>Data Center and Facility 3 Phase UPS</strong>
                    </li>
                    <li>
                    <strong>UPS Management Firmware and Software</strong>
                    </li>
                    <li>
                    <strong>Bypass Panels</strong>
                    </li>
                    <li>
                    <strong>Special Applications</strong>
                    </li>
                    </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>COOLING SOLUTIONS FOR IT EQUIPMENT</h2>
                <p>APC offers cooling solutions for IT equipment, including network closets and data centers.</p>
                <ul>
                  <li><strong>Containment and Air Distribution</strong>
                    <p>Rack Air Distribution</p>
                    <p>Room Air Distribution</p>
                  </li>
                </ul>
                <ul>
                  <li><strong>Chillers</strong>
                    <p>Uniflair Air Cooled Condensers</p>
                    <p>Uniflair Fluid Coolers</p>
                  </li>
                </ul>
                <ul>
                  <li><strong>Close Coupled Air Conditioners</strong>
                    <p>Easy Cooling Row CW</p>
                    <p>Uniflair Chilled Water InRow Cooling</p>
                    <p>Uniflair Direct Expansion InRow Cooling</p>
                    <p>Uniflair InRow Cooling Accessories</p>
                  </li>
                </ul>
                <ul>
                  <li><strong>Room Air Conditioners</strong>
                    <p>Uniflair Rack Mounted Room Cooling</p>
                    <p>Uniflair Ceiling Mounted Split Room Cooling</p>
                  </li>
                </ul>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">POWER DISTRIBUTION</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>Need some power distribution and transfer switch solutions for your network closet or data center IT equipment? APC has them!</p>
                <h3>Rack Power Distribution</h3>
                <p>Rack PDUs provide server-level power availability with a variety of metering and control options, as well as capabilities for environmental monitoring.</p>
                <ul>
                  <li>NetShelter Metered-by-Outlet with Switching Rack PDU</li>
                  <li>NetShelter Switched Rack PDUs</li>
                  <li>NetShelter Metered-by-Outlet Rack PDUs</li>
                  <li>NetShelter Metered Rack PDUs</li>
                  <li>NetShelter Basic Rack PDUs</li>
                  <li>NetShelter Custom Rack PDUs</li>
                  <li>Step-Down Transformer</li>
                </ul>
                <h3>Transfer Switches</h3>
                <p>For seamless transfer of power sources in the data center, other critical applications, and even at home, APC transfer switches really do the trick.</p>
                <ul>
                  <li>Wall (Mounted) Transfer Switches</li>
                  <li>NetShelter Rack Transfer Switches</li>
                </ul>
                <h3>Row 3 Phase Power Distribution</h3>
                <p>Row 3 phase power distribution solutions installed</p>
                <ul>
                  <li>Modular Power Distribution</li>
                  <li>Modular Power Accessories</li>
                  <li>Configurable Power Distribution</li>
                  <li>Configurable Power Accessories</li>
                  <li>iBusway for Data Center</li>
                </ul>
                <h3>Room Power Distribution</h3>
                <ul>
                  <li>Cabinet Power Distribution</li>
                  </ul>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Got Questions? Ready to Get Started? WYN Technologies Can Help!</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                 <p>Reach out to the pros at WYN Technologies today, and let’s discuss your goals, needs, and intended applications for power management and other related IT equipment. Not only does WYN deliver and install whatever APC products you may need; we offer a full slate of business technology solutions. Our name really says it all – WYN is here to provide Whatever You Need to meet your business technology requirements! Call us today at <a href="tel:3368990555">(336) 899-0555</a>.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

    </Layout>
  )
}
export default BrandListPage
